export default {
  common: {
    switchLang: '언어 전환 성공',
    submit: '제출',
    exampleFile: '예시',
    processingData: '처리중입니다. 잠시만 기다려 주세요…',
    preparingData: '준비중입니다. 잠시만 기다려 주세요…',
    indirecting: '연결중입니다. 잠시만 기다려 주세요…',
    pleaseConfirm: '확인해 주세요.',
    applySucc: '신청 성공',
    saveSucc: '저장 완료',
    deleteSucc: '삭제 완료',
    pleaseSelect: '선택하세요',
    new: 'New',
    image: '이미지',
    name: '이름',
    detail: '상세 내용',
    type: '유형',
    search: '서치',
    createAt: 'Create 시간 ',
    download: '다운로드',
    delete: '삭제',
    loading: '로딩중',
    downloading: '다운로드중',
    noMoreData: '더 이상의 데이터가 없습니다. ',
    abort: '중지',
    ok: '확정',
    cancel: '취소',
    downloadAll: '다운로드',
    generate: 'Generate',
    regenerate: 'Regenerate',
    prompt: 'Prompt',
    action: '실행',
    email: 'Email',
    username: '사용자 이름',
    password: '비밀 번호',
    import: 'Import',
    export: 'Export',
    brand: '브랜드',
    guide: '가이드',
    report: '레포트',
    summaryReport: 'Summary 레포트',
    reportManual: '레포트 매뉴얼',
    keyword: '키워드',
    signin: '등록',
    signout: '로그아웃',
    item: "아이템",
    donotshow: 'Do not show',
    product: '상품',
    selectAll: 'All',
    selectReverse: 'Reverse',
    week: 'Week',
    month: 'Month',
    all: '전체',
    distinguish: '포함',
    notDistinguish: '미포함',
    noPermit: 'You have no permission!',
    proportion: '비중',
    color: '컬러',
  },
  route: {
    home: '홈',
    service: '서비스',
    document: '문서',
    about: 'About',
    manual: '매뉴얼',
    tagger: 'AI 이미지 판별기',
    analysis: '트랜드 분석',
    imageDownload: 'Image Download',
    aeye: '유사이미지 찾기',
    my: 'My',
    profile: '사용자',
    order: '권한',
    new: 'New',
    detail: 'Detail',
    generate: '보고서 생성',
    dataCenter: '빅데이터',
    weeklyBoard: '주차 현황판',
    salesData: '매출 데이터',
    stockData: '재고 데이터',
    imageData: '이미지 데이터',
    visual: '스트리트&윈도우',
    streetFashion: '스트리트 패션',
    streetTrend: '스트리트 트랜드',
    windowFashion: '윈도우 패션',
    windowTrend: '윈도우 트랜드',
    salesItem: '아이템별 현황',
    salesTrend: '매출 추세',
    weeklySales: 'Weekly Sales',
    styleSearch: '스타일 조회',
    newProduct: '금주 신상품',
    risingProduct: '주차 스타 상품',
    productDetail: '상품 디테일',
    channel: '판매 채널',
    place: '활용 장소',
    concept: '컨셉',
    specificItem: '세부 아이템 찾기',
    trendMap: '트랜드 지도',
    trendMatrix: '트랜드 메트릭스',
    imageTrendMap: '이미지 트랜드 맵',
    reportConsulting: '레포트&컨설팅',
    consultingReport: '컨설팅 보고서',
    globalTrendReport: '글로벌 트랜드',
    globalTrend: 'Global Trend',
    nlpAnalysis: 'NLP Analysis',
    aiStyleScene: 'AI "StyleScene"',
    creator: 'Creator',
    myLookbook: 'My "StyleScene"',
    searchWords: 'Search Word',
    productName: 'Product Name',
    popularElements: 'Popular Elements',
    priceReport: '가격 분석 보고서',
    itemReport: '아이템 분석 보고서',
    colorReport: '색상 분석 보고서',
    textileReport: '소재 분석 보고서',
    best20Images: '베스트 20 이미지',
    best20Report: '베스트 20 보고서',
    fashionAIDesigner: '패션 AI "디자이너"',
    aiDesign: "AI 디자인",
    fashionSketches: "디자인 스케치",
    modelAIMorph: '모델 변형',
    modelReplace: '모델 교체',
    modelLibrary: '모델 라이브러리',
    fashionAIConcepter: '패션 AI "컨셉트"',
    aiConcepter: 'AI "컨셉터"',
    conceptSketches: '컨셉 스케치',
    fashionAIDrawing: 'Fashion AI "Drawing"',
    aiDrawing: 'AI "Drawing"',
    drawingBook: 'Drawing Book',
    productReviews: 'Product Reviews',
    sns: 'SNS',
    itemDashboard: 'Item Dashboard',
    trendDashboard: 'Trend Dashboard',
    itemCompare: 'Item 비교',
    trendCompare: 'Trend 비교',
    itemSearch: '아이템 조회',
    trendSearch: 'Trend 조회',
    itemPrediction: 'Item Prediction',
    trendPrediction: 'Trend Prediction',
  },
  verify: {
    applyList: '요청 항목',
    approve: '승인',
    reject: '거절'
  },
  profile: {
    changePassword: 'Change password',
    originalPassword: 'Original password',
    newPassword: 'New password',
    confirmPassword: 'Confirm password'
  },
  order: {
    myServices: '마이 서비스',
    applyFreeTrail: '무료 체험 신청',
    service: '서비스',
    level: '레벨',
    status: '상태',
    expireAt: '만료 일자',
    remainImageCount: '사용 가능 이미지 수량',
    selectService: '요청 서비스를 선택하세요',
    noValidService: '사용 가능 서비스가 없습니다. 서비스 목록을 확인하세요.',
    applied: 'Applied',
    approved: 'Approved',
    rejected: 'Rejected',
    basic: 'Basic',
    advanced: 'Advanced',
    premium: 'Premium'
  },
  signup: {
    title: 'F&Plus 계정 만들기',
    confirmPassword: '암호 확인',
    phoneNo: '핸드폰 번호',
    company: '회사',
    area: '지역',
    signup: '가입',
    loginInfo: '요청 사항을 확인하고 결과는 이메일을 통해 알려 드립니다.',
    emailRequired: '이메일 주소 입력해 주세요.',
    emailFormat: '정확한 이메일 주소를 입력해 주세요.',
    userNameRequired: '사용자 아이디를 입력하세요.',
    passwordRequired: '비밀번호를 입력하세요.',
    confirmPasswordRequired: '비밀번호를 다시 입력하세요.',
    phoneNoRequired: '전화번호를 입력하세요.',
    companyRequired: '회사를 입력하세요.',
    areaRequired: '지역을 선택하세요.',
    userNameLength: '사용자 아이디 길이: 4~20자',
    passwordLength: '비밀번호 길이: 6~32자',
    passwordNotMatch: '일관성 없는 비밀번호를 두 번 입력한 경우',
    succSignup: '가입 성공 ',
    succSignupInfo: '새 계정으로 로그인 하세요.',
    emailAlreadyExist: '이메일이 이미 존재함',
    usernameAlreadyExist: '사용자 이름이 이미 있음'
  },
  signin: {
    title: 'F&Plus 등록계정으로 로그인',
    emailUsername: '이메일 또는 등록된 사용자명',
    forgot: '비밀번호를 잊으셨나요 ?',
    signupGuide: 'F&Plus 첫 방문이신가요 ?',
    signupLink: '새 계정 만들기.',
    emailRequired: '정확한 이메일 주소와 사용자명을 입력해 주세요.',
    passwordRequired: '4자리수 이상으로 비밀번호 만드세요.',
    redirectTitle: '여기서는 계정을 사용할 수 없습니다.',
    redirectMsg: '이 계정으로 로그인하려면 {code} 사이트로 이동하세요.',
    intl: '국제적인',
    cn: '중국',
    go: '이동'
  },
  about: {
    desc: '회사 소개',
    manual: 'AI Tagger 매뉴얼',
    contact: '연락처',
    descContent: 'F&PLUS는 패션 인공지능 전문 회사입니다. 1.수집된 빅데이터중 이미지는 인공지능을 통해 ①Category ②ITEM type ③Specific Item 단위로 분류해  패션 트랜드 현황과 트랜드 예측을 지원 합니다. 2.빅데이터와 인공지능의 활용은 브랜딩을 위한 리서치,컨설팅을 지원합니다.',
    contactTitle: 'Ms. Zhong (마케팅, 시장개발 부문장)',
    contactTel: '핸드폰 : 15800974241( WeChat ID 동일)',
  },
  tagger: {
    desc: '설명',
    status: '상태',
    execAt: '시작 시간',
    finishAt: '완료 시간',
    imageCount: '이미지 수량',
    restart: 'Restart',
    deleteTask: '삭제하시겠어요?',
    restartSucc: 'Restart 성공',
    defaultCategory: 'Default Category',
    imageSource: '이미지 소스',
    imageFile: '이미지 파일',
    urlsInExcel: '이미지 URL',
    picsInExcel: 'Pics in Excel',
    uploadImages: '이미지 업로드',
    uploadExcel: '엑셀 업로드',
    clickToUpload: 'Click to Upload',
    succUpload: '이미지 {length}장 업로드 성공',
    submitTask: '실행 할까요 ?',
    invalidImages: '오류 이미지',
    category: 'Category',
    itemType: '아이템',
    specificItem: '상세 아이템',
    runningTagger: 'AI Image Tagger 실행중, 잠시만 기다려 주세요...',
    finishImages: '완료 이미지: {finishCount} / {totalCount}',
    waiting: 'Waiting',
    running: 'Running',
    done: 'Done',
    abort: 'Abort',
    error: 'Error'
  },
  analysis: {
    generateReport: '레포트 생성',
    period: 'Period',
    date: '일자',
    compareDate: '비교 일자',
    platform: '플렛폼',
    deleteReport: '레포트 제거?',
    downloadAll: '전체 다운로드',
    downloadImages: '다운로드 이미지 : {finishCount} / {totalCount}',
    downloadProcess: '다운로드 : {finishCount} / {totalCount}',
    finishDownload: '다운로드 완료!',
    generateSucc: '{type} 레포트 완성 !',
    reportExist: '동일 일자 레포트 있습니다 !',
    reportRegenerate: '동일 일자 레포트가 있지만 다시 생성하길 원하세요 ?',
    group: "Group",
    criterion: "측정 기준",
    sales: "매출액",
    salesVolume: "판매 수량",
    styles: "스타일수",
    graph: "그래프",
    fixedBar: "누적 세로 막대형",
    percentageBar: "100% 기준 누적 막대형"
  },
  data: {
    sumStyle: "스타일수",
    sumStock: "재고수량",
    colorAvg: "색상 평균수",
    sizeAvg: "사이즈 평균수",
    sumMonthSale: "월판매수량",
    saleStockPercentage: "판매량대비 재고 비중",
    newProducts: "금주통록상품",
    repeatProducts: "금주재등록상품",
    currentYearProducts: "을해 상품수",
    previousYearProducts: "이월 상품수",
  },
  visual: {
    when: "언제",
    place: "장소",
    sex: "복종",
    season: "계절"
  },
  salesItem: {
    item: "Item",
    styleAmount: "Style Amount",
    saleQty: "Sale Qty",
    minListPrice: "Min List Price",
    maxListPrice: "Max List Price",
    middleListPrice: "Middle List Price",
    minSalePrice: "Min Sale Price",
    maxSalePrice: "Max Sale Price",
    middleSalePrice: "Middle Sale Price",
    discountRate: "Discount Rate"
  },
  risingProduct: {
    item: "Item",
    weekSaleQty: "This Week Qty",
    lastWeekSaleQty: "Last Week Qty",
    increaseSaleQty: "Increase Qty",
    onSaleTime: "On Sale Time",
    listPrice: "Tag Price",
    salePrice: "Real Price"
  },
  newProduct: {
    item: "아이템",
    weekSaleQty: "판매량 ( 30일 기준 )",
    season: "계절",
    colorCount: "색상수",
    sizeCount: "사이즈수",
    listPrice: "택가격",
    salePrice: "실제가"
  },
  productDetail: {
    detail: "상품 디테일",
    item: "아이템",
    value: "항목",
    newValue: "항목 정리",
    originalValue: "항목 소스",
    saleQty: "판매량 ( 30일 기준 )",
    listPrice: "택가격",
    salePrice: "실제가",
    detailSearch: "상세 조회"
  },
  analysisDetail: {
    pattern: "패턴",
    fG: "FG",
    collar: "카라",
    waistType: "허리 스타일",
    pantLength: "바지 기장",
    pantsFit: "바지 핏",
    channel: "판매 채널",
    place: "활용 장소",
    concept: "컨셉"
  },
  productInfo: {
    style: "스타일 조회",
    baseDate: "적용 기준일",
    logo: "로고",
    yearSeason: "출시년도.시즌",
    color: "색상",
    size: "사이즈",
    textile: "소재",
    patternFG: "패턴/FG",
    collar: "카라",
    waistStyle: "허리스타일",
    pantsLength: "바지 길이",
    pantsFit: "바지핏",
    channel: "채널",
    place: "착장장소",
    concept: "컨셉",
    trendPoint: "트랜드포인트",
    overallSalesRanking: "전체판매순위",
    inItemSalesRanking: "아이템내 판매순위",
    salesPositionAll: "전체대비비중",
    salesPositionItem: "아이템대비비중",
    sales: "판매액",
    salesVolume: "판매수량",
    realPrice: "실제 가격"
  },
  nlpAnalysis: {
    basic: "기본",
    compare: "비교",
    trend: "추이",
    businessType: "복종",
    date: "일",
    compareDate: "비교일",
    startDate: "시작일",
    endDate: "종료일",
    count: "선택 주차",
    item: "아이템",
    length: "단어길이",
    category: "분류",
  },
  imageTrendMap: {
    specificItem: '상세 아이템',
    volume: '볼륨',
    trend: '트랜드 증감',
    quadrant: '분면',
    advice: '판단',
    overall: '전반적인',
    detail: '상세 내용',
  },
  report: {
    target: '대상',
    group: '그룹',
    product: '상품',
    fashion: '패션',
    etc: '기타',
    season: '계절',
    currentYear: '올해',
    previousYear: '이월',
    category: '대분류',
    item: '아이템',
    midPrice: '중심가',
    realMidPrice: '실제 중심가',
    tagMidPrice: 'TAG 중심가',
    discount: '할인율',
    priceRange: '가격 범위',
    max: '최대',
    midt: '중심(T)',
    midr: '중심(실)',
    min: '최소',
    sales: "매출액",
    style: "스타일",
    color: "색상",
    sku: "SKU",
    stock: "재고량",
    criterion: "측정기준",
    textile: "소재",
  },
  globalTrend: {
    year: "년도",
    season: "시즌",
    sex: '성별',
    category: '카테고리',
    item: '아이템',
    difference: '차이',
    proportionInRecency: '최신값 비중',
    specificItem: '상세 아이템',
    trendPoint: '트랜드포인트',
    noticeWarning: '주의 : 패션쇼에서 제공되는 사진들은 오직 내부 참고 목적으로만 활용하실 수 있습니다. 다운로드나 재공유, 배포 등의 행위는 엄격히 금지되어 있음을 알려드립니다. 우리의 서비스를 통해 얻은 영감과 정보는 귀하의 창작과 분석에만 사용해 주시길 바랍니다.'
  },
  gpt: {
    noticeInfo: "GPT모델을 사용한 가상의 이미지 입니다. 이미지는 참고용으로 재.공유등의 활용은 안됩니다. 상품 기획, 디자인 설계를 위한 참고용으로만 사용 가능합니다.",
    noticeWarning: "주의 : 성적 수치심, 혐오감을 주는 이미지는 인공지능 모델의 표현을 방지를 위한 노력을 하지만 그럼에도 보여진다면 신고하여 주시기 바랍니다. ( 신고 버튼 ) ",
    noticeWarningConcept: `주의: 1.성적 수치심, 혐오감을 주는 이미지는 인공지능 모델의 표현을 방지를 위한 노력을 하지만 그럼에도 보여진다면 신고하여 주시기 바랍니다. ( 신고 버튼 )
2.참고 브랜드 사용에 대한 지침: 참고 브랜드는 단순한 참고점으로만 활용해 주십시오. 이는 사용자 여러분의 창의성을 기반으로 새로운 상품을 창조하고 디자인하는 데 있어 도움을 주기 위한 것입니다. 참고 브랜드를 직접적으로 모방하는 것이 아니라, 여러분의 독창적인 아이디어와 결합하여 새로운 디자인을 탄생시키는 데 사용해 주시길 바랍니다.`,
    modelNotice: "[주의]\n*기본 참고 브랜드: {0}\n*고객사별 맞춤형 참고 브랜드는 논의 및 견적 합의 후 개별 개발합니다.",
    report: "신고",
    comfirmReport: "신고 이미지를 확인하시겠습니까?",
    sex: '성별',
    women: '여성',
    man: '남성',
    child: '아동',
    childGirl: '여아',
    childBoy: '남아',
    style: '스타일',
    place: '장소',
    trendPoint: 'Trend Point',
    notice: '주의',
    desc: '설명',
    status: '상태',
    execAt: '시작 시간',
    finishAt: '완료 시간',
    imageCount: '이미지 수량',
    restart: '재시작',
    when: '작성 시점',
    runningInfo: 'GPT Creator 실행중, 잠시만 기다려 주세요...',
    item: '아이템',
    specificItem: '세부아이템',
    fit: '핏',
    collar: '카라',
    sleeve: '소매',
    front: '앞단',
    subsidiaryMaterials: '부자재',
    features: '특징',
    fabric: '소재',
    pattern: '패턴',
    length: '길이',
    pose: '포즈',
    age: '나이',
    face: '얼굴',
    season: '계절',
    targetFace: 'Target Face',
    sourceImage: 'Source Image',
    userAI: "사용자 AI",
    prompt: '상세표현',
    mask: 'Mask',
    upload: '업로드',
    selfDrawing: 'Self "Drawing"',
    select: '선택',
    option: '옵션',
    selfPrompt: '직접 "입력"',
    color: '색상'
  }
}
